import React from "react";
import { Outlet, Link } from "react-router-dom";
import Dropdown from 'rsuite/Dropdown';
import 'rsuite/dist/rsuite.min.css';
import '../styles/index.scss';

const renderMobileMenuButton = (props, ref) => {
	return (
		<div {...props} ref={ref}>
			<div class="d-flex">
				<i className="fa fa-bars" style={{
					fontSize: "24px",
					paddingRight: "9px"
				}}></i>
				<img className="vglLogo" src="/vgllogo.jpg" alt="Valley Green Leather" style={{
					width:'200px',
					maxWidth: '100%',
					height:'auto',
				}}/>
			</div>
		</div>
	)
}

const Layout = () => {
	return (
		<>
			<nav className="d-block d-sm-none navbar bg-dark">
				<Dropdown renderToggle={renderMobileMenuButton}>
					<Dropdown.Item as="a" href="/">Home</Dropdown.Item>
					<Dropdown.Menu title="Products">
						<Dropdown.Menu title="Kits">
							<Dropdown.Item as="a" href="/products/kits/original">Original Trilogy</Dropdown.Item>
							<Dropdown.Item as="a" href="/products/kits/prequel">Prequel</Dropdown.Item>
							<Dropdown.Item as="a" href="/products/kits/accessory">Accessories</Dropdown.Item>
						</Dropdown.Menu>
						<Dropdown.Separator />
						<Dropdown.Menu title="Belts">
							<Dropdown.Item as="a" href="/products/belts/original">Original Trilogy</Dropdown.Item>
							<Dropdown.Item as="a" href="/products/belts/prequel">Prequel</Dropdown.Item>
						</Dropdown.Menu>
						{/* <Dropdown.Menu title="Face Characters">
							<Dropdown.Item as="a" href="/products/facecharacters/anhobiwan">Old Ben Kenobi</Dropdown.Item>
							<Dropdown.Item as="a" href="/products/facecharacters/rotjluke">Jedi Knight Luke</Dropdown.Item>
							<Dropdown.Item as="a" href="/products/facecharacters/nihilus">Nihilus</Dropdown.Item>
						</Dropdown.Menu> */}
						<Dropdown.Separator />
						<Dropdown.Menu title="Pouches">
							<Dropdown.Item as="a" href="/products/pouches/small">Small</Dropdown.Item>
							<Dropdown.Item as="a" href="/products/pouches/wallet">Wallet</Dropdown.Item>
							<Dropdown.Item as="a" href="/products/pouches/cellphone">Communicator (Cell Phone)</Dropdown.Item>
							<Dropdown.Item as="a" href="/products/pouches/large">Large</Dropdown.Item>
						</Dropdown.Menu>
						<Dropdown.Menu title="Saber Hangers">
							<Dropdown.Item as="a" href="/products/saberhangers/acolyteclip">Acolyte Covertec</Dropdown.Item>
							<Dropdown.Item as="a" href="/products/saberhangers/covertec">Covertec</Dropdown.Item>
							<Dropdown.Item as="a" href="/products/saberhangers/dring">D-Ring</Dropdown.Item>
						</Dropdown.Menu>
						<Dropdown.Separator />
						<Dropdown.Menu title="Guitar Straps">
							<Dropdown.Item as="a" href="/products/guitarstrap/prequel">Prequel</Dropdown.Item>
						</Dropdown.Menu> 
						<Dropdown.Menu title="Marvel">
							<Dropdown.Item as="a" href="/products/marvel/capstraps">Cap. Shield Straps</Dropdown.Item>
						</Dropdown.Menu> 
					</Dropdown.Menu>
					<Dropdown.Item as="a" href="/contact">Contact</Dropdown.Item>
				</Dropdown>
			</nav>
			<nav className="d-none d-sm-block navbar navbar-expand-sm bg-dark">
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav mr-auto" id="#primaryNav">
						<li className="nav-item">
							<Link to="/" className="nav-link">Home</Link>
						</li>
						<li className="nav-item">
							<Dropdown title="Products" trigger="hover">
								<Dropdown.Menu title="Kits">
									<Dropdown.Item as="a" href="/products/kits/original">Original Trilogy</Dropdown.Item>
									<Dropdown.Item as="a" href="/products/kits/prequel">Prequel</Dropdown.Item>
									<Dropdown.Item as="a" href="/products/kits/accessory">Accessories</Dropdown.Item>
								</Dropdown.Menu>
								<Dropdown.Separator />
								<Dropdown.Menu title="Belts">
									<Dropdown.Item as="a" href="/products/belts/original">Original Trilogy</Dropdown.Item>
									<Dropdown.Item as="a" href="/products/belts/prequel">Prequel</Dropdown.Item>
								</Dropdown.Menu>
								{/* <Dropdown.Menu title="Face Characters">
									<Dropdown.Item as="a" href="/products/facecharacters/anhobiwan">Old Ben Kenobi</Dropdown.Item>
									<Dropdown.Item as="a" href="/products/facecharacters/rotjluke">Jedi Knight Luke</Dropdown.Item>
									<Dropdown.Item as="a" href="/products/facecharacters/nihilus">Nihilus</Dropdown.Item>
								</Dropdown.Menu> */}
								<Dropdown.Separator />
								<Dropdown.Menu title="Pouches">
									<Dropdown.Item as="a" href="/products/pouches/small">Small</Dropdown.Item>
									<Dropdown.Item as="a" href="/products/pouches/wallet">Wallet</Dropdown.Item>
									<Dropdown.Item as="a" href="/products/pouches/cellphone">Communicator (Cell Phone)</Dropdown.Item>
									<Dropdown.Item as="a" href="/products/pouches/large">Large</Dropdown.Item>
								</Dropdown.Menu>
								<Dropdown.Menu title="Saber Hangers">
									<Dropdown.Item as="a" href="/products/saberhangers/acolyteclip">Acolyte Covertec</Dropdown.Item>
									<Dropdown.Item as="a" href="/products/saberhangers/covertec">Covertec</Dropdown.Item>
									<Dropdown.Item as="a" href="/products/saberhangers/dring">D-Ring</Dropdown.Item>
								</Dropdown.Menu>
								<Dropdown.Separator />
								<Dropdown.Menu title="Guitar Straps">
									<Dropdown.Item as="a" href="/products/guitarstrap/prequel">Prequel</Dropdown.Item>
								</Dropdown.Menu>
								<Dropdown.Menu title="Marvel">
									<Dropdown.Item as="a" href="/products/marvel/capstraps">Cap. Shield Straps</Dropdown.Item>
								</Dropdown.Menu> 
							</Dropdown>
						</li>
						<li className="nav-item">
							<Link to="/contact" className="nav-link">Contact</Link>
						</li>
					</ul>
					<form class="form-inline my-2 my-lg-0">
						<img className="vglLogo" src="/vgllogo.jpg" alt="Valley Green Leather" />
					</form>
				</div>
			</nav>
			<div id="body"><Outlet /></div>
		</>
	)
};

export default Layout;