import OptionConstants from '../../../constants/options';
import DisclaimerConstants from '../../../constants/disclaimer';
import { Carousel } from 'react-responsive-carousel';

let carouselContent = [
  {
    img: "/img/products/marvel/capstraps/1.jpeg",
    caption: "Captain America Shield straps"
  },
  {
    img: "/img/products/marvel/capstraps/2.jpeg",
    caption: "Assembly - Unscrew the small loop of leather and keep the three barred hardware attached."
  },
  {
    img: "/img/products/marvel/capstraps/3.jpeg",
    caption: "Feed this loop through one of the connectors."
  },
  { 
    img: "/img/products/marvel/capstraps/4.jpeg",
    caption: "Press the screw post gently against the connector."
  },
  { 
    img: "/img/products/marvel/capstraps/5.jpeg",
    caption: "Join the other side of the leather through the post, and screw it down."
  },
  { 
    img: "/img/products/marvel/capstraps/6.jpeg",
    caption: "With the 'good side' of the leather up, slide the long strap into other end with the metal in the middle."
  },
  { 
    img: "/img/products/marvel/capstraps/7.jpeg",
    caption: "Loop the tail over the connector."
  },
  { 
    img: "/img/products/marvel/capstraps/8.jpeg",
    caption: "Slide it though the three bar'd metal piece."
  },
  { 
    img: "/img/products/marvel/capstraps/9.jpeg",
    caption: "Then under the loop on the second connector."
  },
  { 
    img: "/img/products/marvel/capstraps/10.jpeg",
    caption: "Bring it back through the metal piece."
  },
  { 
    img: "/img/products/marvel/capstraps/11.jpeg",
    caption: "And through the top bar as well."
  },
  { 
    img: "/img/products/marvel/capstraps/12.jpeg",
    caption: "Pull it all the way through - and assembly is complete!"
  },
];


const Component = () => {
	return [
    <h2 className="title">Captain America Shield Straps</h2>,
    <p className="content">These genuine leather straps are a kit designed to provide the leather straps for the inside 
      of a 3D Printed, or Metal, Captain America Sheild.  They are intended to be accurate to the pictures found of the
      screen used straps.
      <br /><br />
      The connectors on the cross section of cap sheild that we used have an inner space on the connectors of XXmm, and 
      center to center the connectors are XXmm spaced apart from each other.
    </p>,
    <p className="content">{DisclaimerConstants.handMade}</p>,
    <div className="m-auto pt-3">
      <Carousel 
                autoPlay={true}
                swipable={true}
                stopOnHover={true}
                infiniteLoop={true}
                showThumbs={false}
                >
                  {
                    carouselContent.map(carouselItem => {
                      return <div className="picture-wrapper">
                        <img className="capstrap" src={carouselItem.img} alt={carouselItem.caption} />
                        { carouselItem.caption ? <p className="legend">{carouselItem.caption}</p> : null }
                      </div>
                    })
                  }
              </Carousel>
    </div>,
    <p className="content more">{OptionConstants.more}</p>,
    <p className="content option">Pricing: A set of two straps is $44.99.</p>,
    <p className="content option">
      When ordering a Cap Strap Kit we only need to know that the dimensions will work for your shield, and that the 
      medium brown color shown is acceptable.  If you would like another color we are happy to dye them according to 
      the color chart found below:</p>,
    <p className="content option">Optional - Note the standard color used is AG-5,2. { OptionConstants.color}</p>,
    <p className="content disclaimer">{ DisclaimerConstants.vegTan }</p>,
  ];
};
  
  export default Component;